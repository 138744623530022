/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.array.join.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Dialog } from 'vant';
import XButton from 'vux/src/components/x-button';
import UpImage from '@/components/otder/UpImage';
export default {
  components: {
    XButton: XButton,
    UpImage: UpImage
  },
  data: function data() {
    return {
      man: true,
      food: [],
      radio: '1',
      Txtmessage: '',
      imgSrc: '',
      //上传的评论
      limitNum: 4,
      imgList: [],
      RateText: '非常好',
      id: this.$route.query.id,
      msgObj: {},
      i: 0,
      orderId: '',
      mealId: ''
    };
  },
  methods: {
    succe: function succe() {
      this.man = true;
    },
    baic: function baic() {
      this.man = false;
    },
    onChange: function onChange(val, y) {
      switch (val) {
        case 1:
          this.food[y].RateText = '极差';
          break;

        case 2:
          this.food[y].RateText = '差';
          break;

        case 3:
          this.food[y].RateText = '一般';
          break;

        case 4:
          this.food[y].RateText = '好';
          break;

        default:
          this.food[y].RateText = '非常好';
      }
    },
    //获取餐品
    getfoodlist: function getfoodlist() {
      var _this = this;

      this.food = []; //  获取用户的餐品，并对此进行评价

      this.$api.Meal.getOrderDetail(this.orderId, this.mealId).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          var foo = data.foodList;

          for (var i = 0; i < foo.length; i++) {
            var fool = {
              RateText: '非常好',
              Ratevalue: 5,
              foodName: foo[i].foodName,
              id: foo[i].id
            };

            _this.food.push(fool);
          }
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },
    postMsg: function postMsg() {
      var _this2 = this;

      if (!this.Txtmessage) return this.$utils.Tools.toastShow('内容不能为空');
      var temp = [];

      var _iterator = _createForOfIteratorHelper(this.imgList),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var imgs = _step.value;
          temp.push(imgs.serverId);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      var imgPath = temp.join(','); //整理要提交的评论

      var foodArray = [];

      for (var i = 0; i < this.food.length; i++) {
        var foods = {
          id: this.food[i].id,
          commentStar: this.food[i].Ratevalue
        };
        foodArray.push(foods);
      }

      Dialog.confirm({
        title: '是否提交评论？'
      }).then(function () {
        _this2.$api.Meal.otderAppraiseKey({
          subOrderId: _this2.id,
          mealStar: 5,
          mealEvaluate: _this2.Txtmessage,
          imgPath: imgPath,
          foodArray: foodArray,
          commentStar: _this2.man ? 1 : 0
        }).then(function (response) {
          var code = response.code,
              msg = response.msg;

          if (+code !== _this2.$code.success) {
            _this2.$utils.Tools.toastShow(msg);
          } else {
            _this2.$utils.Tools.toastShow(msg);

            _this2.$router.go(-1);
          }
        }).catch(function () {
          _this2.$utils.Tools.httpError();
        });
      }).catch(function () {// on cancel
      });
    }
  },
  created: function created() {
    this.msgObj = JSON.parse(this.$utils.Store.getLocalItem('msgobj'));
    this.orderId = this.msgObj.orderId;
    this.mealId = this.msgObj.mealId;
    this.getfoodlist();
  }
};